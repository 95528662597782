.home_image {
  width: 100%;
  height: 100vh;
  background-image: url("/public/images/headlight_bg.jpeg");
  /* background-image: url("../images/home-wallpaper.jpeg"); */
  position: sticky;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  object-position: center;
  color: #f5d8d8;
  background-color: rgb(23, 33, 53);
  top: 0;
  z-index: -10;
}

.shopButton {
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border: none !important;
}
.home_card {
  height: 50rem;
}
@media screen and (max-width: 768px) {
  .home_card {
    height: 40rem;
  }
}
@media screen and (max-width: 500px) {
  .formInput {
    font-size: 16px !important;
  }
  button:hover {
    background-color: #f5f7f9 !important;
    border-color: #d9d9d9 !important;
    color: black !important;
  }
}
.glow {
  animation: glow 2s infinite alternate;
}
@keyframes glow {
  0% {
    text-shadow: 0 0 10px hsla(0, 0%, 100%, 0.3), 0 0 20px #ffffff80;
  }
  50% {
    text-shadow: 0 0 20px hsla(0, 0%, 100%, 1), 0 0 30px #b80c08;
  }
  100% {
    text-shadow: 0 0 10px hsla(0, 0%, 100%, 0.3),
      0 0 20px hsla(0, 0%, 100%, 0.5);
  }
}
.list-style li {
  list-style: inside;
  margin-left: 20px;
}
.list-style h3 {
  font-size: 16px;
  font-weight: 500;
}
.ant-select-focused:where(
    .css-dev-only-do-not-override-1uweeqc
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #e5e7eb !important;
  box-shadow: none !important;
}
.carousel .ant-image {
  width: 100% !important;
  height: 100% !important;
}
